import { some, forEach } from 'lodash';
import React from 'react';

import connect from 'components/lib/connect';
import FeatureNotification from './feature_notification';
import FeatureNotificationTypes from 'scripts/domain/models/agent_notification/feature_notification_types';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';

const ukKycRegistration = {
  feature: FeatureNotificationTypes.UK_KYC,
  roleFlags: ['teamManagement'],
  flag: 'ukKycRegistration',
  orgIds: undefined,
  key: 'uk-kyc-notification',
  text: '[Action Required] UK KYC Registration',
  subtext:
    'You are receiving this notice because your company had Voice or SMS traffic on UK phone numbers over the past few months and action is required to avoid the blocking of Voice and SMS traffic in the UK.',
  url: 'https://connect.gladly.com/docs/help-documentation/article/uk-kyc-registration-action-required/',
  urlText: 'View Details',
  endTime: 1728709199000,
};

const answerThreadsClassroom = {
  feature: FeatureNotificationTypes.AT_CLASSROOM,
  roleFlags: ['teamManagement'],
  flag: 'atClassroomNotification',
  key: 'at-classroom-notification',
  text: 'Set Up Answer Threads for Success',
  subtext:
    'Join us in this Classroom session to explore how to ensure Answer Threads operates at its peak and identify opportunities to optimize your Answers, making Answer Threads a true extension of great service.',
  url: 'https://gladly.zoom.us/meeting/register/tZEvc-2rqj8rEtEGjM4-Ba_Ng1lQof8qQaMb',
  urlText: 'REGISTER',
  endTime: 1729177200000,
};

const skDashboard = {
  feature: FeatureNotificationTypes.SK_DASHBOARD,
  roleFlags: ['teamManagement'],
  flag: 'sidekickNotifications',
  key: 'sk-dashboard-notification',
  text: 'Unlock Sidekick Dashboard Insights',
  subtext:
    'Discover how to measure success and identify improvement opportunities in your Sidekick implementation in just 4 minutes.',
  url:
    'https://connect.gladly.com/docs/help-documentation/article/sidekick-dashboard/#access-the-daily-trends-dashboard',
  urlText: 'WATCH NOW',
  endTime: 1729745999000,
};

const CURRENT_FEATURES = [ukKycRegistration, answerThreadsClassroom, skDashboard];

export function FeatureNotificationList({ agentPreferences, organization }) {
  const isFeatureEnabled = useIsFeatureEnabled();

  let featureNotifications = [];
  forEach(CURRENT_FEATURES, featureProps => {
    if (shouldShowNotification(featureProps, agentPreferences, isFeatureEnabled, organization)) {
      featureNotifications.push(<FeatureNotification {...featureProps} />);
    }
  });

  if (!featureNotifications.length) {
    return null;
  }

  return <React.Fragment>{featureNotifications}</React.Fragment>;
}

const FeatureNotificationListContainer = connect(mapStateToProps)(FeatureNotificationList);

function mapStateToProps({ getProvider }) {
  const agentPreferences = getProvider('agentPreferences').get();
  const organization = getProvider('auth').get()?.claims;

  return {
    agentPreferences,
    organization,
  };
}

export function useHasFeaturesToShow(agentPreferences, organization) {
  const isFeatureEnabled = useIsFeatureEnabled();
  return some(CURRENT_FEATURES, featureProps => {
    return shouldShowNotification(featureProps, agentPreferences, isFeatureEnabled, organization);
  });
}

function shouldShowNotification(featureProps, agentPreferences, isFeatureEnabled, organization) {
  const roleRestricted = featureProps.roleFlags && featureProps.roleFlags.length;
  const restrictedAndHasRole = some(featureProps.roleFlags, roleFlag => isFeatureEnabled(roleFlag));
  const flagRestricted = featureProps.flag;

  const orgRestricted = !!featureProps.orgIds;
  const belongsToOrg = some(featureProps.orgIds, orgId => orgId === organization?.orgId);

  const currentTime = Date.now();
  const startRestricted = !!featureProps.startTime;
  const afterStart = startRestricted ? currentTime > featureProps.startTime : false;
  const endRestricted = !!featureProps.endTime;
  const beforeEnd = endRestricted ? currentTime < featureProps.endTime : false;

  return (
    (!orgRestricted || belongsToOrg) &&
    (!roleRestricted || restrictedAndHasRole) &&
    (!flagRestricted || isFeatureEnabled(featureProps.flag)) &&
    (!startRestricted || afterStart) &&
    (!endRestricted || beforeEnd) &&
    !agentPreferences.hasSeenFeatureNotification(featureProps.feature)
  );
}

export default FeatureNotificationListContainer;
